/* Component Dependencies */
var cardArticleTemplate = require('templates/cardArticle.hbs');
var cardArticleDestinationTemplate = require('templates/cardArticleDestination.hbs');
var cardArticledestinationListingsTemplate = require('templates/cardArticle-destinationListings.hbs');
var cardArticleMirrorTemplate = require('templates/cardArticleMirror.hbs');
var cardArticleMirrorDestinationTemplate = require('templates/cardArticleMirror-destination.hbs');
var cardArticle2ATemplate = require('templates/cardArticle2A.hbs');
var cardArticle3ATemplate = require('templates/cardArticle3A.hbs');
var cardArticleAccentTemplate = require('templates/cardArticleAccent.hbs');
var cardArticleMediaTemplate = require('templates/cardArticleMedia.hbs');
var cardArticleBadgeTemplate = require('templates/cardArticle.hbs');
var cardArticleDestinationFullList = require('templates/cardArticle-destinationFullList.hbs');
var cardArticleIconWithLink = require('templates/cardArticleIconWithLink.hbs');
var AriesComponent = require('libs/aries-component');
var AriesComponent = require('libs/aries-component');
var $ = require('jquery');
var mobileFirstPicture = require('content_libs/mobileFirstPicture');

AriesComponent.create({

  type: 'cardArticle',

  template: {
    'cardArticle': cardArticleTemplate,
    'cardArticleDestination': cardArticleDestinationTemplate,
    'cardArticleMirror' : cardArticleMirrorTemplate,
    'cardArticle2A':cardArticle2ATemplate,
    'cardArticle3A':cardArticle3ATemplate,
    'cardArticleAccent':cardArticleAccentTemplate,
    'cardArticleBadge':cardArticleTemplate,
    'cardArticleMedia': cardArticleMediaTemplate,
    'cardArticleMirror-destination': cardArticleMirrorDestinationTemplate,
    'cardArticle-destinationListings': cardArticledestinationListingsTemplate,
    'cardArticle-destinationFullList': cardArticleDestinationFullList,
    'cardArticleIconWithLink': cardArticleIconWithLink
  },
  bindEvents: function() {
    var _self = this;
    mobileFirstPicture.init(_self);
    if($('html').hasClass('ie')) {
      picturefill();
    }
    
  },	 	 
  init: function () {
    this.bindEvents();
  }
});
